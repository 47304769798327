import localforage from 'localforage';

const state = {
	includeColor: {
		value: "#81fd81"
	},
	excludeColor: {
		value: "#ff7979"
	},
	include: new Map(),
	exclude: new Map()
}

const getters = {
	// Get map
	getColorMap: state => new Map([ ...state.include, ...state.exclude ]),
	// Get array of keys from the map
	getIncludeKeys: state => [...state.include.keys()],
	getExcludeKeys: state => [...state.exclude.keys()],
	// Get colors
	getIncludeColor: state => state.includeColor.value,
	getExcludeColor: state => state.excludeColor.value,
}

const mutations = {
	// Reset keywords
	resetInclude() {
		state.include = new Map();
	},
	resetExclude() {
		state.exclude = new Map();
	},
	// Load from key, value array
	loadInclude(state, array) {
		state.include = new Map(array);
	},
	loadExclude(state, array) {
		state.exclude = new Map(array);
	},
	// Convert array of keywords to map that contains color information
	setInclude(state, keywords) {
		state.include = new Map(
			Object.entries(
				keywords.reduce((acc,curr)=> (acc[curr]=state.includeColor,acc),{})
			)
		);
		localforage.setItem('includeKeywords', [...state.include]);
	},
	setExclude(state, keywords) {
		state.exclude = new Map(
			Object.entries(
				keywords.reduce((acc,curr)=> (acc[curr]=state.excludeColor,acc),{})
			)
		);
		localforage.setItem('excludeKeywords', [...state.exclude]);
	},
	// Set colors from hex string
	setIncludeColor(state, color) {
		state.includeColor = { value: color };
		state.include = new Map(
			Object.entries(
				[...state.include.keys()].reduce((acc,curr)=> (acc[curr]=state.includeColor,acc),{})
			)
		);
		localforage.setItem('includeKeywords', [...state.include]);
	},
	setExcludeColor(state, color) {
		state.excludeColor = { value: color };
		state.exclude = new Map(
			Object.entries(
				[...state.exclude.keys()].reduce((acc,curr)=> (acc[curr]=state.excludeColor,acc),{})
			)
		);
		localforage.setItem('includeKeywords', [...state.include]);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
}