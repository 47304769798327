const state = {
	isLoading: false
}

const getters= {
	isLoading() {
		return state.isLoading
	}
}

const mutations = {
	setLoading(state, value) {
		state.isLoading = value;
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}