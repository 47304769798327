<template>
	<b-form-radio :value="id" :class="{ 'row-transition': isTransition, 'row-new': isNew }" ref="ref-group-row">
		<b-row no-gutters class="text-left">
			<b-col cols="7">
				{{ name }}
			</b-col>
			<b-col cols="5">
				<span>{{ "(" + length + ")" }}</span>
				<b-icon-pencil-fill @click.prevent="$emit('edit-ref')" class="ml-1"/>
				<BaseColorPicker class="ml-1" style="display: inline" :color="color" @change="$emit('edit-color', $event)" />
			</b-col>
		</b-row>
	</b-form-radio>
</template>

<script>
export default {
	props: {
		id: String,
		name: String,
		color: String,
		length: Number
	},
	data() {
		return {
			isTransition: false,
			isNew: false
		}
	},
	watch: {
		length() {
			console.log(`${this.name} length changed`);
			console.log(this.$refs['ref-group-row']);
			this.isTransition = true;
			this.isNew = true;
			setTimeout(() => this.isNew = false, 100);
			setTimeout(() => this.isTransition = false, 600);

		}
	}
}
</script>

<style scoped>
.row-transition {
	transition: background-color 0.5s linear;
	-moz-transition: background-color 0.5s linear;
	-webkit-transition: background-color 0.5s linear;
	-ms-transition: background-color 0.5s linear;
}
.row-new {
	background: var(--success);
	transition: background-color 0s linear;
	-moz-transition: background-color 0s linear;
	-webkit-transition: background-color 0s linear;
	-ms-transition: background-color 0s linear;
}
</style>