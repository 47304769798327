<template>
	<div class="chart-wrap">
		<h6>Progress:</h6>
		<div id="chart">
			<apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	computed: {
		...mapGetters('groups', ['customGroupsandUndecided', 'getSize']),
		series() {
			return this.customGroupsandUndecided.map(group => group.refIds.size);
		},
		chartOptions() {
			return {
				labels: this.customGroupsandUndecided.map(group => group.name),
				colors: this.customGroupsandUndecided.map(group => {
					return group.color !== "none" ? group.color : "#bdbdbd"
				}),
				chart: {
					type: 'donut',
				},
				dataLabels: {
					enabled: false
				},
				legend: {
					position: 'bottom',
				},
				responsive: [{
					breakpoint: 1400,
					options: {
						chart: {
						},
						legend: {
							show: false
						}
					}
				}],
			}
		}
	},
}
</script>