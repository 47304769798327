import { mapGetters } from "vuex";

import io from "socket.io-client";

export default {
  name: 'TheToolbar',
  data() {
    return {
      socket: null
    }
  },
  computed: {
    ...mapGetters({
      refs: 'references/getAllRefs'
    })
  },
  mounted() {
      const domain = "https://compute.sr-accelerator.com";
      // const domain = "http://127.0.0.1:5000";
      this.socket = io.connect(domain);
  },
  methods: {
    getEmbeddings() {
			console.log("Get embeddings...");
      // On Progress
      this.socket.on('embedProgress', function(msg) {
          console.log(msg);
      });
      // On Completion
      this.socket.on('embedComplete', (embeddings) => {
        // Close socket
        // this.socket.close();
        // Set all embeddings
        for(let key of Object.keys(embeddings)) {
          if(this.refs && this.refs[key]) {
            this.refs[key]['embedding'] = embeddings[key];
          }
        }
        this.$store.commit('references/setRefs', this.refs);
        console.log("Embeddings complete");
      });
      // Emit to socket
      if (this.socket) {
        try {
          this.socket.emit('getArticleEmbeddings', this.refs)
        } catch (error) {
          console.log(error);
        }
      } else {
				console.log("Error creating socket");
			}
    },
    getProbabilities() {
			console.log("Get probabilities...")
      // TODO: Add timeout
      return new Promise((resolve) => {
        // On Completion
        this.socket.on('probComplete', (probabilities) => {
          // Close socket
          // this.socket.close();
          // Set all embeddings
          for(let key of Object.keys(probabilities)) {
            if(this.refs && this.refs[key]) {
              this.refs[key]['probability'] = probabilities[key];
            }
          }
					this.$store.commit("references/updateRefs", this.refs)
          // this.$store.commit('references/setRefs', refs);
          console.log("Probability complete");
          resolve(Object.values(this.refs));
      });
        // Emit to socket
        if (this.socket) {
          try {
            this.socket.emit('getArticleProbabilities', this.refs)
          } catch (error) {
            console.log(error);
          }
        } else {
					console.log("Error creating socket");
				}
      })
    },
  }
}