import localforage from 'localforage'

const state = {
	// Stores references in object keyed by ID
	refs: null,
}

const getters = {
	// Get the entire refs object
	getAllRefs: state => {
		return state.refs;
	},
	// Get an array of references based on array of id's
	getRefsArray: state => idList => {
		return idList.map(id => state.refs[id]);
	}
}

const mutations = {
	reset(state) {
		state.refs = null;
	},
	// Set all references
	setRefs(state, refs) {
		// Set refs
		state.refs = refs;
		// Update local storage
		localforage.setItem("refs", state.refs);
	},
	// Update a single reference
	updateRef(state, ref) {
		state.refs[ref.id] = ref;
		// Update local storage
		localforage.setItem("refs", state.refs);
	},
	/**
	 * Update/merge the refs object with new values
	 * @function
	 * @param {object} state - current state of the store
	 * @param {object} refs - reference object to merge keyed by refID
	 */
	updateRefs(state, refs) {
		state.refs = { ...state.refs, ...refs }
		// Update local storage
		localforage.setItem("refs", state.refs);
	},
	// Remove groups for array of refIds
	removeGroups(state, refIds) {
		refIds.forEach(refId => {
			state.refs[refId].group = null;
		})
		// Update local storage
		localforage.setItem("refs", state.refs);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}