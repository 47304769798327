<template>
	<b-modal id="advanced-search" ref="advanced-search" size="xl" title="Advanced Search" @show="onShow">
		<b-alert show>
			Enter PubMed or Ovid search syntax below to perform an advanced search.<br />
			Currently, Mesh terms, wildcards and adjacency operators (e.g. near/3) are not supported.
		</b-alert>
		<!-- Listen to on change event instead of v-on:input to achieve same result as v-model.lazy -->
		<editor
			ref="queryEditor"
			mode="text"
			theme="chrome"
			v-bind:value="searchText"
			v-on:input="update($event)"
			v-on:init="editorInit"
			width="100%"
			height="500"
			v-bind:options="editorOptions"
		></editor>
		<template #modal-footer>
			<b-button @click="cancel">Cancel</b-button>
			<b-button variant="primary" @click="search">Search</b-button>
		</template>
	</b-modal>
</template>

<script>
import ace from "vue2-ace-editor";
import PolyglotMode from "../syntax/polyglot-syntax.js";
import Vue from "vue";

export default {
	name: "BaseAdvancedSearchModal",
	props: {
		searchText: String,
	},
	data() {
		return {
			editorOptions: {
				showPrintMargin: false,
				wrap: true
			}
		};
	},
	components: {
		editor: ace
	},
	methods: {
		editorInit(editor) {
			// Ace editor settings
			require("brace/mode/javascript"); //language
			require("brace/theme/chrome");
			editor.setFontSize("16px");
			editor.setValue(this.searchText, 1);
		},
		async onShow() {
			await Vue.nextTick();
			// Setup polyglot syntax
			const customMode = new PolyglotMode();
			this.$refs.queryEditor.editor.getSession().setMode(customMode);
			this.$refs.queryEditor.editor.focus();
			// setTimeout(this.update(), 100);
		},
		update(e) {
			if (e) {
				this.$emit("input", e);
			}
		},
		search() {
			this.$emit("search", this.searchText);
			this.$refs["advanced-search"].hide();
		},
		cancel() {
			this.$refs['advanced-search'].hide();
			// Clear search
			this.$emit("input", "");
		}
	}
};
</script>