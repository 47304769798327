<template>
	<div class="w-100">

		<b-form-group class="w-100">
			<b-form-radio-group
				class="w-100"
				v-model="selected"
				buttons
				button-variant="outline-primary"
				stacked
			>
				<template v-for="group in fixedGroups">
					<b-form-radio :value="group.key" :key="group.key">
						<span class="float-left">{{ group.name }}</span>
						<span class="float-right">{{ "(" + getSize(group.key) + ")" }}</span>
					</b-form-radio>
				</template>
			</b-form-radio-group>
		</b-form-group>

		<b-form-group class="w-100" label="Groups">
			<b-form-radio-group
				class="w-100"
				v-model="selected"
				buttons
				button-variant="outline-primary"
				stacked
			>
				<ReferenceGroupsRow
					v-for="group in customGroups"
					:key="group.key"
					:id="group.key"
					:name="group.name"
					:color="group.color"
					:length="getSize(group.key)"
					@edit-ref="editModal(group)"
					@edit-color="$store.commit('groups/setGroup', { ...group, color: $event })"
				/>
			</b-form-radio-group>
		</b-form-group>
		<b-button @click="triggerNewGroup" variant="success" class="w-100">New Group <b-icon-plus /></b-button>

		<!-- Modal to edit group -->
		<b-modal ref="edit-modal" id="edit" title="Edit Group">
			<b-form-group label="Group Name:">
				<b-form-input v-model="editGroup.name" placeholder="Enter group name"></b-form-input>
			</b-form-group>

			<BaseInputHotkeys v-model="editGroup.hotkeys" label="Hotkeys:" />

			<b-form-group label="Color:" class="colorPicker">
				<input class="color-picker" type="color" :value="editGroup.color" @change="editGroup.color = $event.target.value">
			</b-form-group>

			<b-form-group class="text-center mt-4">
				<b-button
					variant="danger"
					size="sm"
					@click="removeGroup"
				>
					Delete Group
				</b-button>
			</b-form-group>

			<template #modal-footer>
				<b-button
					size="sm"
					class="float-right"
					@click="$refs['edit-modal'].hide()"
				>
					Close
				</b-button>
				<b-button
					variant="primary"
					size="sm"
					class="float-right"
					@click="saveEdit"
				>
					Save
				</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex'

import ReferenceGroupsRow from "./ReferenceGroupsRow.vue"
import BaseInputHotkeys from "./BaseInputHotkeys.vue"

import { BFormGroup, BFormRadioGroup, BFormRadio, BModal, BFormInput, BFormTags } from '@iebh/bootstrap-vue';
Vue.component('b-form-group', BFormGroup);
Vue.component('b-form-radio-group', BFormRadioGroup);
Vue.component('b-form-radio', BFormRadio);
Vue.component('b-modal', BModal);
Vue.component('b-form-input', BFormInput);
Vue.component('b-form-tags', BFormTags);

import { BIconPencilFill, BIconPlus } from '@iebh/bootstrap-vue';
Vue.component('b-icon-pencil-fill', BIconPencilFill);
Vue.component('b-icon-plus', BIconPlus);

export default {
	name: "ReferenceGroups",
	components: {
		ReferenceGroupsRow,
		BaseInputHotkeys
	},
	data() {
		return {
			editGroup: {
				key: "N/A",
				name: "N/A",
				hotkeys: [],
				color: ""
			},
		}
	},
	computed: {
		...mapState('groups', ['groupList', 'selectedGroup']),
		...mapGetters('groups', ['fixedGroups', 'customGroups', 'getSize']),
		...mapGetters('hotkeys', ['doesHotkeyExist', 'getHotkeysForGroup']),
		selected: {
			set(selected) {
				this.$store.commit('groups/setSelectedGroup', selected)
			},
			get() {
				return this.selectedGroup;
			}
		}
	},
	methods: {
		triggerNewGroup() {
			this.$store.dispatch("groups/newGroup");
		},
		editModal(group) {
			this.editGroup = { ...group };
			this.editGroup.hotkeys = this.getHotkeysForGroup(group.key);
			this.$refs['edit-modal'].show();
		},
		saveEdit() {
			this.$refs['edit-modal'].hide();
			this.$store.commit('groups/setGroup', this.editGroup);
			this.$store.dispatch('hotkeys/saveHotkeys', { hotkeys: this.editGroup.hotkeys, group: this.editGroup.key })
		},
		removeGroup() {
			this.$store.dispatch('removeGroup', this.editGroup);
			this.$refs['edit-modal'].hide();
		},
	},
}
</script>

<style>
.colorPicker .active {
	border: 3px solid black !important;
}
.color-preview {
	width: 20px;
	height: 100%;
	background-color: red;
	padding: 10px;
	border: 3px solid black;
}
</style>