import localforage from 'localforage'
import filenameMixin from './filenameMixin';

export default {
	mixins: [filenameMixin],
	methods: {
		async save() {

			const metadata = await localforage.getItem("metadata");
			const refs = await localforage.getItem("refs");
			const groupList = await localforage.getItem("groupList");
			const hotkeys = await localforage.getItem("hotkeys");
			const includeKeywords = await localforage.getItem("includeKeywords");
			const excludeKeywords = await localforage.getItem("excludeKeywords");

			const saveObject = {
				metadata,
				refs,
				groupList,
				hotkeys,
				includeKeywords,
				excludeKeywords
			}

			const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(saveObject));
			const downloadAnchorNode = document.createElement('a');
			downloadAnchorNode.setAttribute("href", dataStr);
			downloadAnchorNode.setAttribute(
				"download",
				"scrn_"
					+ this.getProjectName()
					+ " "
					+ this.getDateString()
					+ ".scrn"
			);
			document.body.appendChild(downloadAnchorNode); // required for firefox
			downloadAnchorNode.click();
			downloadAnchorNode.remove();
		}
	}
}