<template>
	<b-row>
		<b-col cols="auto">
			{{
				(rowsPerPage * (currentPage - 1)) + 1}}-{{Math.min(rowsPerPage * currentPage, totalRows)}} of {{ totalRows }} Articles
		</b-col>
		<b-col cols="auto">
			<b-pagination
				:value="currentPage"
				@input="$emit('input', $event)"
				:per-page="rowsPerPage"
				:total-rows="totalRows"
				aria-controls="table"
				size="sm"
			></b-pagination>
		</b-col>
	</b-row>
</template>

<script>
export default {
	props: {
		currentPage: Number,
		rowsPerPage: Number,
		totalRows: Number
	}
}
</script>r