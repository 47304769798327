export default {
	methods: {
		formatCitation(reference) {
			let citation = "";
			if (reference.authors) {
				citation += this.formatAuthors(reference.authors) + " " ;
			}
			if (reference.title) {
				citation += reference.title + ". ";
			}
			if (reference.journal) {
				citation += reference.journal;
			}
			if (reference.year) {
				citation += ". " + reference.year;
			}
			if (reference.volume) {
				citation += "; " + reference.volume;
				if (reference.number) {
					citation += "(" + reference.number + ")";
				}
				if (reference.pages) {
					citation += ":" + reference.pages
				}
			}
			return citation
		},
		formatAuthors(arr) {
			let string = "";
			if (arr.length > 0) {
				for (let i = 0; i < Math.min(2, arr.length); i++) {
					string = string.concat(arr[i] + ", ");
				}
				string = string.concat(arr[arr.length - 1]);
				string += ", et al."
			}
			return string;
		},
		formatAbstract(ab) {
			return ab ? ab.replace(/\n/gi, "<br/>") : '';
		},
	},
}