<template>
	<b-form inline>
		<b-form-group
			:description="`Filter (${filterTargets.join(', ')})`"
			label-for="filterInput"
		>
			<b-input-group size="sm">
				<b-form-input
					v-model="filterText"
					type="search"
					id="filterInput"
					placeholder="Type to Search"
					@keydown.enter.prevent="filter(filterText, filterTargets)"
				></b-form-input>
				<b-input-group-append>
					<b-button
						v-if="filteredRefs"
						variant="danger"
						@click="clearFilter()"
					>Clear</b-button>
				</b-input-group-append>
				<b-input-group-append>
					<b-button
						@click="filter(filterText, filterTargets)"
					>Search</b-button>
				</b-input-group-append>
				<b-input-group-append>
					<b-dropdown
						variant="outline-secondary"
						right
						size="sm"
					>
						<b-form-group class="m-2" label="Filter Targets">
							<b-form-checkbox-group
								v-model="filterTargets"
								:options="filterTargetsOptions"
								stacked
							></b-form-checkbox-group>
						</b-form-group>
					</b-dropdown>
				</b-input-group-append>
			</b-input-group>
		</b-form-group>
		<b-form-group description="Advanced boolean search" class="ml-2">
			<b-button-group size="sm">
				<b-button v-b-modal.advanced-search>Advanced Search</b-button>
				<b-button
					v-if="searchedRefs"
					variant="danger"
					@click="clearSearch()"
				>Clear</b-button>
				<BaseAdvancedSearchModal
					:searchText="searchText"
					@input="searchText = $event"
					@search="advancedSearch($event)"
				/>
			</b-button-group>
		</b-form-group>
	</b-form>
</template>

<script>
import { parse } from "@iebh/polyglot";
import { mapGetters } from 'vuex';

export default {
	data() {
		return {
			filterText: "",
			filterTargets: ["title", "abstract", "keywords"],
			filteredRefs: null,
			filteredRefsTracker: 0,
			searchText: "",
			searchedRefs: null,
			searchedRefsTracker: 0,
			filterTargetsOptions: [
				"title",
				"abstract",
				"keywords",
				"authors",
				"year",
				"urls",
				"notes",
				"researchNotes",
				"journal",
				"date",
				"pages",
				"volume",
				"number",
				"isbn",
				"label",
				"caption",
				"address",
			],
			polyglotOptions: {
				groupLines: false,
				groupLinesAlways: true,
				removeNumbering: false,
				preserveNewLines: false,
				replaceWildcards: false,
				transposeLines: true,
				highlighting: false,
			},
		}
	},
	computed: {
		...mapGetters('references', ['getRefsArray']),
		...mapGetters('groups', ['getAllGroupRefIds']),
		refsTracker() {
			return this.filteredRefsTracker + this.searchedRefsTracker;
		}
	},
	watch: {
		refsTracker() {
			if (this.filteredRefs && this.searchedRefs) {
				this.$emit("change", this.setIntersection(this.filteredRefs, this.searchedRefs));
			} else if (this.filteredRefs) {
				this.$emit("change", this.filteredRefs);
			} else if (this.searchedRefs) {
				this.$emit("change", this.searchedRefs);
			} else {
				this.$emit("change", null);
			}
			this.$emit("isFiltering", false);
		}
	},
	methods: {
		clearFilter() {
			this.filterText = "";
			this.$emit("filterText", "");
			this.filteredRefs = null;
			this.filteredRefsTracker += 1;
		},
		clearSearch() {
			this.searchText = "";
			this.searchedRefs = null;
			this.searchedRefsTracker += 1;
		},
		filter(filterText, filterTargets) {
			filterText = filterText.trim();
			this.$emit("isFiltering", true);
			this.$emit("filterText", filterText);
			this.filteredRefs = this.filterString(filterText, filterTargets);
			this.filteredRefsTracker += 1;
		},
		advancedSearch(searchString) {
			searchString = searchString.trim();
			this.$emit("isFiltering", true);
			this.searchedRefs = this.runSearch(searchString);
			this.searchedRefsTracker += 1;
		},
		filterString(string, fields) {
			const regex = new RegExp(string, "i");
			let returnSet = new Set();
			this.getRefsArray(this.getAllGroupRefIds)
				.forEach(ref => {
					// If string is empty or string appears in one of the filter targets
					if (string === "" || fields.some(target => regex.test(ref[target]))) {
						returnSet.add(ref.id);
					}
				})
			return returnSet;
		},
		runSearch(searchString) {
			var searchTree = parse(searchString, this.polyglotOptions);
			// Check search tree is not empty
			if (!searchTree || searchTree.length < 1) {
				console.log("Empty search tree in parseSearch");
				return;
			}
			// If query is split into lines, parse last line only
			if (searchTree[0].type == 'line') {
				let i = searchTree.length - 1;
				return this.parseToIds(searchTree[i].nodes);
			}
			// Otherwise parse the whole tree
			return this.parseToIds(searchTree);
		},
		parseToIds(searchTree) {
			// Check search tree is not empty
			if (!searchTree || searchTree.length < 1) {
				console.log("Empty search tree in parseToIds");
				return;
			}
			// Iterate over tree
			let operator = null;
			let prevIds = null;
			while (searchTree.length > 0) {
				let ids = null;
				let branch = searchTree.shift();

				// Recursive function for groups and refs
				switch (branch.type) {
					case "group":
						ids = this.parseToIds(branch.nodes);
						break;
					case "ref":
						if (!branch.cond) {
							ids = this.parseToIds(branch.nodes[0]);
						} else {
							let prevIds = null;
							branch.nodes.forEach(line => {
								let lineIds = this.parseToIds(line);
								if (!prevIds && lineIds) {
									prevIds = lineIds;
								}
								else if (prevIds && lineIds) {
									if (branch.cond == "OR") {
										prevIds = this.setUnion(prevIds, lineIds);
									} else if (branch.cond == "AND") {
										prevIds = this.setIntersection(prevIds, lineIds);
									} else if (branch.cond == "NOT") {
										prevIds = this.setNot(prevIds, lineIds);
									} else {
										console.error("Unknown branch.cond (boolean operator)")
									}
								} else {
									console.error("Undefined lineIds");
								}
							})
							ids = prevIds;
						}
						break;
					case "phrase":
						ids = this.searchPhrase(branch.content, branch.field);
						break;
					case "joinOr":
					case "joinAnd":
					case "joinNot":
						operator = branch.type;
						continue;
					default:
						console.log("Unknown branch type:", branch.type);
						continue;
				}

				// If ids, prevIds and operator exist, perform set operation
				if (ids && prevIds && operator) {
					prevIds = this.setOperation(ids, prevIds, operator);
					operator = null;
				}
				// Otherwise, if no operator with ids and prevIds, throw error
				else if (!operator && ids && prevIds) {
					console.error("No operator found for ids and prevIds");
				}
				// Otherwise assign current ids to prevIds
				else if (!prevIds && ids) {
					prevIds = ids;
				}
				// Oops
				else {
					console.log(branch);
					console.log(ids, prevIds, operator);
					console.error("This error should not be reached");
				}
			}
			// Return ids for search
			return prevIds;
		},
		searchPhrase(content, field) {
			// Array to hold what fields to search through
			let fields = [];
			// Look at field and determine which fields in ref to search
			switch (field) {
				case "Title search":
					fields = ["title"];
					break;
				case "Title/abstract search":
					fields = ["title", "abstract"];
					break;
				case "Text word":
					fields = ["title", "abstract", "keywords"];
					break;
				case "Abstract":
					fields = ["abstract"];
					break;
				case "Author":
				case "First personal author name in a citation":
				case "Full authors name":
				case "Last author's name in citation":
					fields = ["authors"];
					break;
				case "Keyword field (.kf.)":
					fields = ["keywords"];
					break;
				case "Title, abstract, keyword (.ti,ab,kf.)":
					fields = ["title", "abstract", "keywords"];
					break;
				case "Journal issue numbers":
					fields = ["volume", "number"];
					break;
				case "Journal title, abbreviation or ISSN":
					fields = ["journal", "isbn", "label"];
					break;
				case "DOI or publisher ID for online articles":
					fields = ["doi"];
					break;
				// A lot of fields dont match the fields avaliable so the default will search everything
				default:
					fields = this.filterTargetsOptions;
					break;
			}
			// Return IDs
			return this.filterString(content, fields);
		},
		setOperation(ids, prevIds, operator) {
			// Perform union on both sets of IDs
			if (operator == "joinOr") {
				return this.setUnion(prevIds, ids);
			}
			// Perform intersection on both sets of IDs
			if (operator == "joinAnd") {
				return this.setIntersection(prevIds, ids);
			}
			// Perform not operation
			if (operator == "joinNot") {
				return this.setNot(prevIds, ids);
			}
		},
		setUnion(setA, setB) {
			return new Set([...setA, ...setB]);
		},
		setIntersection(setA, setB) {
			return new Set([...setA].filter(id => setB.has(id)));
		},
		// Return setA, excluding any articles in setB
		setNot(setA, setB) {
			return new Set([...setA].filter(id => !setB.has(id)));
		}
	}
}
</script>