import localforage from 'localforage'

// This function fixes an error when trying to store set objects on local storage by first converting to an array
function Set_toJSON(key, value) {
	if (typeof value === 'object' && value instanceof Set) {
		return [...value];
	}
	return value;
}

const getDefaultGroupList = () => {
	return new Map([
		[
			"undecided",
			{
				key: "undecided",
				name: "Undecided",
				fixed: true,
				refIds: new Set(),
				color: "none"
			},
		],
		[
			"all",
			{
				key: "all",
				name: "All References",
				fixed: true,
				refIds: new Set(),
				color: "none"
			},
		],
		[
			"g1",
			{
				key: "g1",
				name: "Exclude",
				fixed: false,
				refIds: new Set(),
				color: "#FFB3B3"
			},
		],
		[
			"g2",
			{
				key: "g2",
				name: "Include",
				fixed: false,
				refIds: new Set(),
				color: "#ADE4FF"
			},
		]
	])
}

const state = {
	// Holds the key of the currently selected group
	selectedGroup: "undecided",
	// Change tracker
	groupListChangeTracker: 1,
	// Holds the information of the avaliable groups
	groupList: getDefaultGroupList(),
}

const getters = {
	// Get all reference ID's of all articles
	getAllGroupRefIds: state => {
		const group = state.groupList.get("all");
		return state.groupListChangeTracker && Array.from(group.refIds);
	},
	// Get group from groupKey
	getGroup: state => groupKey => {
		return state.groupListChangeTracker && state.groupList.get(groupKey);
	},
	// Get the reference ID's of the currently selected group
	getSelectedGroupRefIds: state => {
		const group = state.groupList.get(state.selectedGroup);
		return state.groupListChangeTracker && Array.from(group.refIds);
	},
	// Get the reference ID's of the input group
	getRefIds: state => groupKey => {
		const group = state.groupList.get(groupKey);
		return state.groupListChangeTracker && Array.from(group.refIds);
	},
	// Get the length of the set for a certain group
	getSize: state => key => {
		const group = state.groupList.get(key);
		return state.groupListChangeTracker && group.refIds.size;
	},
	// Return a list of groups that cannot be modified by the user
	fixedGroups: state => {
		let groups = [];
		for (let value of state.groupList.values()) {
			if (value.fixed) {
				groups.push(value);
			}
		}
		return state.groupListChangeTracker && groups;
	},
	// Return a list of customizable groups
	customGroups: state => {
		let groups = [];
		for (let value of state.groupList.values()) {
			if (!value.fixed) {
				groups.push(value);
			}
		}
		return state.groupListChangeTracker && groups;
	},
	// Return a list of customizable groups and undecided (for progress chart)
	customGroupsandUndecided: state => {
		let groups = [];
		for (let value of state.groupList.values()) {
			if (!value.fixed || value.key === "undecided") {
				groups.push(value);
			}
		}
		return state.groupListChangeTracker && groups;
	},
	// Return a list of customizable groups
	customGroupsAsMap: state => {
		let groups = new Map();
		for (let [key, value] of state.groupList.entries()) {
			if (!value.fixed) {
				groups.set(key,value);
			}
		}
		return state.groupListChangeTracker && groups;
	},
	/**
	 * Get the next group key e.g. "g4" for pushing new groups
	 * @function
	 * @param  {object} state
	 * @param  {object} getters
	 */
	getNextGroupKey: (state, getters) => {
		const groups = getters.customGroups;
		let max = 1;
		for (const group of groups) {
			// Strip all non numeric characters
			let key = group.key.replace(/\D/g,'');
			// Convert to number
			key = parseInt(key);
			// If key is greater than max, make that the new max
			if (key > max) max = key;
		}
		// Add 1 to max to make it the next group
		max += 1;
		max = max.toString();
		// Return new group key
		return "g" + max;
	}
}

const mutations = {
	reset(state) {
		state.selectedGroup = "undecided";
		state.groupListChangeTracker = 1;
		state.groupList = getDefaultGroupList();
	},
	setGroup(state, payload) {
		state.groupList.set(payload.key, payload);
		state.groupListChangeTracker += 1;
		// Update local storage
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	},
	// Delete a group based on the key
	deleteGroup(state, groupKey) {
		state.groupList.delete(groupKey);
		state.groupListChangeTracker += 1;
		// Update local storage
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	},
	// Set entire groupList object
	setGroupList(state, list) {
		// Ensure all refIds are set objects
		list.forEach(group => group.refIds = new Set(group.refIds))
		// Set Grouplist
		state.groupList = list;
		state.groupListChangeTracker += 1;
		// Update local storage
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	},
	// Reset grouplist to default
	resetGroupList(state) {
		// Set Grouplist
		state.groupList = getDefaultGroupList();
		state.groupListChangeTracker = 1;
		// Update local storage
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	},
	// Set the currently selected group
	setSelectedGroup(state, key) {
		state.selectedGroup = key;
	},
	// Set the reference ID's of a group
	setGroupRefIds(state, payload) {
		let group = state.groupList.get(payload.key);
		group.refIds = new Set(payload.refIds);
		state.groupListChangeTracker += 1;
		// Update local storage
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	},
	/**
	 * Add a new group to groupList
	 * @function
	 * @param {object} state - current state of the store
	 * @param {string} key - key of group to push
	 */
	pushNewGroup(state, key) {
		state.groupList.set(
			key,
			{
				key: key,
				name: "New Group",
				fixed: false,
				refIds: new Set(),
				color: "#FFF88F"
			}
		)
		state.groupListChangeTracker += 1;
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	},
	// Push reference object to group
	pushRefsToGroup(state, payload) {
		let group = state.groupList.get(payload.groupKey);
		for (let key of Object.keys(payload.refs)) {
			group.refIds.add(key);
		}
		state.groupListChangeTracker += 1;
		// Update local storage
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	},
	// Remove a ref ID from the undecided list (when moving to a group)
	removeFromUndecided(state, refId) {
		let group = state.groupList.get("undecided");
		group.refIds.delete(refId);
		state.groupListChangeTracker += 1;
		// Update local storage
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	},
	/**
	 * Remove a ref ID from all groups excluding the all references group
	 * @function
	 * @param {object} state - current state of the store
	 * @param {string} refId - reference ID to remove from groups
	 */
	// removeFromGroups(state, refId) {
	//   for(let [key, value] of state.groupList) {
	//     if (key !== "all") {
	//       value.refIds.delete(refId);
	//     }
	//   }
	//   state.groupListChangeTracker += 1;
	//   // Update local storage
	//   localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	// },
	/**
	 * Remove multiple refs from all groups excluding the all references group
	 * @function
	 * @param {object} state - current state of the store
	 * @param {object} refs - object of references one wishes to remove
	 */
	removeRefsFromGroups(state, refs) {
		for(let [key, value] of state.groupList) {
			if (key !== "all") {
				Object.keys(refs).forEach(key => {
					value.refIds.delete(key);
				})
			}
		}
		state.groupListChangeTracker += 1;
		// Update local storage
		localforage.setItem("groupList", JSON.parse(JSON.stringify([...state.groupList], Set_toJSON)));
	}
}

const actions = {
	newGroup({ commit, getters }) {
		let key = getters.getNextGroupKey;
		commit('pushNewGroup', key);
	},
	// Take groups refIds and assign to undecided then delete group
	removeGroup({ commit, getters }, group) {
		const undecidedRefs = getters.getRefIds("undecided");
		commit('setGroupRefIds', {
			key: "undecided",
			refIds: [...undecidedRefs, ...group.refIds]
		})
		commit('deleteGroup', group.key);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}