<template>
		<div v-if="isLoading" class="m-3 p-2 d-flex align-items-center border border-secondary rounded">
				<strong>Loading...</strong>
				<b-spinner class="ml-auto"></b-spinner>
		</div>
</template>

<script>
import { mapGetters } from "vuex";

import { BSpinner } from '@iebh/bootstrap-vue';
import Vue from 'vue';
Vue.component('b-spinner', BSpinner);

export default {
		name: 'LoadingSpinner',
		computed: mapGetters('loading', ['isLoading'])
}
</script>