<template>
	<b-form-group :label="label">
		<b-form-tags
			ref="hotkeyEdit"
			:value="value"
			@input="$emit('input', $event)"
			duplicate-tag-text="Duplicate Key"
			@keyup.native="newHotkey"
		></b-form-tags>
	</b-form-group>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	props: {
		value: Array,
		label: String,
	},
	computed: {
		...mapGetters({
			doesHotkeyExist: "hotkeys/doesHotkeyExist"
		})
	},
	methods: {
		newHotkey(e) {
			if(!this.doesHotkeyExist(e.code))
				this.$refs.hotkeyEdit.addTag(e.code);
			else {
				console.log("Hotkey already exists: ", e.code);
				this.$refs.hotkeyEdit.duplicateTags.push(e.code);
				this.$refs.hotkeyEdit.newTag = "";
			}
		}
	}
}
</script>