<template>
	<b-modal ref="settings-modal" id="settings-modal" title="Settings">
		<p class="my-4">Select all references with Ctrl+A. Navigate reference table by using up/down arrow keys.</p>
		<b>Hotkeys:</b>
		<BaseInputHotkeys
			v-for="hotkey of hotkeys"
			:key="hotkey.action"
			:label="hotkey.label"
			v-model="hotkey.keys"
		/>
		<template #modal-footer>
			<b-button
				variant="danger"
				class="float-left"
				@click="reset()"
			>
				Reset to Default
			</b-button>
			<b-button @click="hide()">
				Cancel
			</b-button>
			<b-button variant="info" @click="save">
				Save
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseInputHotkeys from './BaseInputHotkeys.vue';
export default {
	components: { BaseInputHotkeys },
	name: 'TheSettingsModal',
	async mounted() {
		this.$root.$on('show-settings-modal', async () => {
			this.hotkeys = this.getHotkeys();
			await this.$nextTick()
			this.$refs['settings-modal'].show();
		});
		this.hotkeys = this.getHotkeys();
	},
	computed: {
		...mapGetters({
			getHotkeysForGroup: "hotkeys/getHotkeysForGroup",
		})
	},
	data() {
		return {
			hotkeys: []
		}
	},
	methods: {
		hide() {
			this.$refs['settings-modal'].hide();
		},
		close() {
			this.$refs['settings-modal'].hide();
		},
		reset() {
			this.$store.commit('hotkeys/reset');
			this.hotkeys = this.getHotkeys();
		},
		save() {
			this.hotkeys.forEach(hotkey => {
				this.$store.dispatch('hotkeys/saveHotkeys', { hotkeys: hotkey.keys, group: hotkey.action })
			})
			this.$refs['settings-modal'].hide();
		},
		getHotkeys() {
			let hotkeys = [
				{
					action: "show-single-abstract",
					label: "Show/hide abstract:",
				},
				{
					action: "remove-reference-group",
					label: "Remove group/return to unassigned:",
				},
				{
					action: "copy-title-text",
					label: "Copy title of selected reference:",
				},
				{
					action: "search-database (bond-library)",
					label: "Search Bond University Library for selected references:",
				},
				{
					action: "search-database (pubmed)",
					label: "Search Pubmed for selected references:",
				},
				{
					action: "search-database (google-scholar)",
					label: "Search Google Scholar for selected references:",
				}
			]
			return hotkeys.map(hotkey => {
				return { ...hotkey, keys: this.getHotkeysForGroup(hotkey.action) }
			})
		}
	}
}
</script>

<style>
</style>