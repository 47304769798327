<template>
	<div class="mt-5">
		<b>To begin screening, choose a library or drop it here</b>
		<b-form-file
			class="logo"
			v-model="file"
			:state="file ? true : false"
			placeholder="Choose a library or drop it here..."
			drop-placeholder="Drop file here..."
			accept=".xml, .csv, .json, .nbib, .ris, .tsv, .scrn"
			@change="fileInputEvent($event.target.files[0])"
		></b-form-file>
		<b-button v-if="file" variant="success" class="mt-3" @click="fileUpload">Upload</b-button>
	</div>
</template>

<script>
import importMixin from "../mixins/importMixin"
import loadMixin from "../mixins/loadMixin"

import { BFormFile } from '@iebh/bootstrap-vue';
import Vue from 'vue';
Vue.component('b-form-file', BFormFile);

export default {
	data() {
		return {
			file: null,
		}
	},
	name: 'FileUpload',
	mixins: [importMixin, loadMixin],
	methods: {
		fileInputEvent(file) {
			this.file = file;
		},
		fileUpload() {
			if (this.file) {
				// Read file extention
				const fileExtention = this.getFileExtention(this.file.name);
				console.log(fileExtention);
				if (fileExtention === ".scrn")
					this.loadFile(this.file);
				else
					this.importFile(this.file);
			}
		}
	}
}
</script>

<style>
</style>
