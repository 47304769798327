import * as reflib from "reflib";
import { v4 } from 'uuid';
import { mapGetters } from 'vuex'

export default {
	name: 'FileUpload',
	computed: {
		...mapGetters('references', ['getAllRefs']),
		...mapGetters('groups', ['getRefIds']),
	},
	data() {
		return {
			file: null,
			// Variable to store the filetype information
			fileType: {
				driver: "endnotexml",
				extention: ".xml"
			},
			fileTypeOptions: [
				{
					value: { driver: "endnotexml", extention: ".xml" },
					text: "EndNote XML file"
				},
				{
					value: { driver: "csv", extention: ".csv" },
					text: "Comma Seperated Values"
				},
				{
					value: { driver: "json", extention: ".json" },
					text: "JSON file"
				},
				{
					value: { driver: "medline", extention: ".nbib" },
					text: "MEDLINE / PubMed file"
				},
				{
					value: { driver: "ris", extention: ".ris" },
					text: "RIS file"
				},
				{
					value: { driver: "tsv", extention: ".tsv" },
					text: "Tab Seperated Values"
				}
			]
		}
	},
	methods: {
		importFile(file) {
			this.$store.commit('loading/setLoading', true);
			Promise.resolve()
				.then(() => this.readFile(file))
				.then(contents => this.parseXml(contents))
				// Commit library to the store
				.then(([refs, refList]) => {
					this.$store.commit('references/setRefs', { ...this.getAllRefs, ...refs });
					this.$store.commit('groups/setGroupRefIds', { key: 'all', refIds: [ ...this.getRefIds('all'), ...refList ] });
					this.$store.commit('groups/setGroupRefIds', { key: 'undecided', refIds: [ ...this.getRefIds('undecided'), ...refList ] });
					this.$store.commit('loading/setLoading', false);
					// Show modal promting the user to save their work
					console.log("Showing modal")
					this.$root.$emit('show-save-modal');
				})
		},
		getFileExtention(name) {
			return "." + name.split(".").pop();
		},
		readFile(file) {
			return new Promise(resolve => {
				// Read file extention
				const fileExtention = this.getFileExtention(file.name);
				const fileType = this.fileTypeOptions.find(
					({ value }) => value.extention === fileExtention
				)?.value;
				if (typeof fileType === "undefined")
					console.log("Error: Unsuported file extention");
				else this.fileType = fileType;
				// Convert input file into a buffer
				const reader = new FileReader();
				reader.readAsText(file);
				reader.onloadend = () => resolve(reader.result);
			});
		},
		parseXml(contents) {
			let refs = {};
			let refList = [];
			return new Promise(resolve => {
				reflib
					.parse(this.fileType.driver, contents)
					.on("ref", (ref) => {
						const id = v4();
						refs[id] = { ...ref, group: null, id: id };
						refList.push(id);
					})
					.on("error", (error) => alert(error))
					.on("end", () => {
						// Resolve promise when complete
						resolve([refs, refList]);
					});
			});
		}
	}
}