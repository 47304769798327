<template>
	<div class="w-100">
		<span class="float-right">
			<BaseColorPicker :color="getIncludeColor" @change="$store.commit('keywords/setIncludeColor', $event)"/>
		</span>
		<b-form-group label="Include Keywords:">
			<b-form-tags
				ref="includeKeywords"
				v-model="include"
				separator=",;"
				duplicate-tag-text="Duplicate Word"
			></b-form-tags>
			<div class="float-right mt-1">
				<b-button variant="danger" size="sm" @click="clearAll('include')">Clear All</b-button>
			</div>
		</b-form-group>
		<span class="float-right">
			<BaseColorPicker style="display: inline" :color="getExcludeColor" @change="$store.commit('keywords/setExcludeColor', $event)"/>
		</span>
		<b-form-group label="Exclude Keywords:">
			<b-form-tags
				ref="excludeKeywords"
				v-model="exclude"
				separator=",;"
				duplicate-tag-text="Duplicate Word"
			></b-form-tags>
			<div class="float-right mt-1">
				<b-button variant="danger" size="sm" @click="clearAll('exclude')">Clear All</b-button>
			</div>
		</b-form-group>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters('keywords', ['getIncludeKeys', 'getExcludeKeys', 'getIncludeColor', 'getExcludeColor']),
		include: {
			get() {
				return this.getIncludeKeys;
			},
			set(value) {
				// Convert all keywords to lowercase
				value = value.map(el => el.toLowerCase());
				this.$store.commit('keywords/setInclude', value);
			}
		},
		exclude: {
			get() {
				return this.getExcludeKeys;
			},
			set(value) {
				// Convert all keywords to lowercase
				value = value.map(el => el.toLowerCase());
				this.$store.commit('keywords/setExclude', value);
			}
		}
	},
	methods: {
		clearAll(type) {
			if (type === "include") {
				this.include = []
			} else if (type === "exclude") {
				this.exclude = []
			} else {
				console.error("Invalid tag type:", type);
			}
		}
	}
}
</script>