<template>
	<span v-html="this.outputText" />
</template>

<script>
import { debounce } from "debounce";
import { mapGetters } from 'vuex';

export default {
	props: {
		text: String,
		searchStrings: Array[String]
	},
	data() {
		return {
			outputText: ""
		}
	},
	created() {
		this.outputText = this.sanitizeHtml(this.text);
		// Add function in created to ensure correct debounce
		this.highlight = debounce(this.highlightFunc, 100);
		// Run highlight function when created
		this.highlight();
	},
	computed: {
		...mapGetters('keywords', ['getColorMap']),
	},
	watch: {
		searchStrings: function() {
			this.highlight()
		},
		getColorMap: function() {
			this.highlight()
		},
	},
	methods: {
		sanitizeHtml(input) {
		// Sanitize html
		var element = document.createElement('div');
		element.innerText = input;
		return element.innerHTML;
		},
		highlightFunc() {
			if (this.text) {
				this.outputText = this.sanitizeHtml(this.text);
				// Create regex string
				const regexString =
					[ ...this.getColorMap.keys(), ...this.searchStrings ]
						.filter(Boolean)
						.map(s => s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&'))
						.sort((a, b) => b.length - a.length)
						.join('|');
				// Check for regex before perfoming replacement
				if(regexString.length) {
					// Replace all output text with spans for color
					this.outputText = this.outputText.replace(new RegExp(regexString, "gi"), match => {
						// If no colormap match then must be from the search strings
						let color = this.getColorMap.get(match.toLowerCase())?.value;
						if(!color) color = "rgb(255, 255, 125)"
						return `<span style="color: black; background: ${color};">${match}</span>`;
					})
				}
			}
		}
	}
}
</script>