import localforage from 'localforage'

const getDefaultHotkeys = () => {
	return new Map([
		["Digit1", "g1"],
		["Space", "g1"],
		["Digit2", "g2"],
		["ArrowLeft", "g2"],
		// These keys are used in the table
		["KeyA", "show-single-abstract"],
		["Enter", "show-single-abstract"],
		["KeyU", "remove-reference-group"],
		["NumpadAdd", "copy-title-text"],
		["NumpadDivide", "search-database (bond-library)"],
		["NumpadMultiply", "search-database (pubmed)"],
		["NumpadSubtract", "search-database (google-scholar)"],
		["ArrowUp", false],
		["ArrowDown", false],
		["ControlLeft", false],
	])
}

const state = {
	hotkeys: getDefaultHotkeys(),
	changeTracker: 1
}

const getters = {
	doesHotkeyExist: state => key => {
		return state.changeTracker && state.hotkeys.has(key)
	},
	getHotkeys: state => {
		return state.changeTracker && state.hotkeys;
	},
	getGroupFromKeyCode: state => key => {
		return state.changeTracker && state.hotkeys.get(key);
	},
	getHotkeysForGroup: state => group => {
		let hotkeys = [];
		for (let [key, value] of state.hotkeys.entries()) {
			if (value === group)
				hotkeys.push(key)
		}
		return state.changeTracker && hotkeys;
	}
}

const mutations = {
	reset(state) {
		state.hotkeys = getDefaultHotkeys();
		state.changeTracker++;
	},
	// Function to load hotkeys from localstorage
	setHotkeys(state, payload) {
		state.hotkeys = payload;
		state.changeTracker++;
	},
	/**
	 * Function to set new hotkeys when editing a group
	 * @param  {Object} state
	 * @param  {Object} payload
	 * @param  {string[]} payload.hotkeys - An array of hotkey codes
	 * @param  {Object} payload.group - The group key to use when assigning hotkeys
	 */
	setHotkeysForGroup(state, payload) {
		for (let hotkey of payload.hotkeys) {
			state.hotkeys.set(hotkey, payload.group);
		}
		state.changeTracker++;
	},
	/**
	 * Function to remove old hotkeys when editing a group
	 * @param  {Object} state
	 * @param  {string} groupKey - The groupKey to remove hotkeys for
	 */
	removeHotkeys(state, groupKey) {
		for (let [key, value] of state.hotkeys.entries()) {
			if (value === groupKey)
				state.hotkeys.delete(key);
		}
		state.changeTracker++;
	}
}

const actions = {
	/**
	 * Action to remove old hotkeys and set new ones
	 * @param  {Function} commit - Function to call mutations
	 * @param  {Object} payload
	 * @param  {string[]} payload.hotkeys - An array of hotkey codes
	 * @param  {String} payload.group - The group key to use when assigning hotkeys
	 */
	saveHotkeys ({ commit }, payload) {
		commit('removeHotkeys', payload.group)
		commit('setHotkeysForGroup', payload)
		localforage.setItem("hotkeys", [...state.hotkeys]);
	}

}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}