<template>
	<div v-if="isSpeechSupported">
		<b-button-group>
			<b-button v-if="!isListening" @click="startListening()" variant="success" size="sm">
				Start Listening
			</b-button>
			<b-button v-if="isListening" @click="stopListening()" variant="danger" size="sm">
				Stop Listening
			</b-button>
			<div id="tooltip-listening">
				<b-button variant="info" size="sm"><b-icon-question-circle-fill /></b-button>
			</div>
			<b-tooltip target="tooltip-listening" triggers="hover" placement="bottom">
				<b>Screenatron Voice Commands</b><br/><br/>
				Get screenatron to begin listening for voice commands to assign references to groups.<br/>
				Simply select a reference, click "Start Listening" and begin saying the desired group you wish to assign the reference to (e.g. "Include", "Exclude", etc.)
			</b-tooltip>
		</b-button-group>
	</div>
</template>

<script>
import { BIconQuestionCircleFill, BTooltip } from "@iebh/bootstrap-vue";
export default {
	components: {
		BTooltip,
		BIconQuestionCircleFill,
	},
	props: {
		groups: Array
	},
	data() {
		return {
			isSpeechSupported: true,
			isListening: false,
			recognition: undefined
		}
	},
	computed: {
		groupNames() {
			return this.groups.map(g => g.name.toLowerCase());
		}
	},
	watch: {
		groupNames() {
			this.initializeGrammar();
		}
	},
	mounted() {
		const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

		if (typeof SpeechRecognition === "undefined") {
			console.error("Speech recognition not supported")
			// Remove button
			this.isSpeechSupported = false;
			return
		}

		this.recognition = new SpeechRecognition();
		this.recognition.continuous = true;
		this.recognition.lang = "en-US";
		this.recognition.interimResults = false;
		this.recognition.maxAlternatives = 10;
		this.initializeGrammar();
		this.recognition.addEventListener("result", this.onResult);
		this.recognition.addEventListener("end", () => {
			console.log("Speech API disconnected");
			// Restart speech api if was auto-disconnected
			if (this.isListening) {
				console.log("Restarting listener");
				this.recognition.start();
			}
		});
	},
	methods: {
		startListening() {
			this.isListening = true;
			this.recognition.start();
		},
		stopListening() {
			this.isListening = false;
			this.recognition.stop();
		},
		onResult(event) {
			let lastIndex = event.results.length - 1;
			let possibleResults = event.results[lastIndex];
			// Check if any results match the group names
			for (let result of possibleResults) {
				for (let group of this.groups) {
					if (new RegExp(group.name, "i").test(result.transcript)) {
						this.$emit("input", group.key);
						return;
					}
				}
			}
			console.log("No group name matches found", possibleResults[0].transcript);
		},
		initializeGrammar() {
			const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
			// Assign grammar list based on group names
			if (SpeechGrammarList) {
				let speechRecognitionList = new SpeechGrammarList();
				let grammar = "#JSGF V1.0; grammar groups; public <group> = " + this.groupNames.join(" | ") + ";";
				speechRecognitionList.addFromString(grammar, 1);
				this.recognition.grammars = speechRecognitionList;
			}
		}
	}
}
</script>