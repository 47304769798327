<template>
	<div>
		<input class="color-picker" type="color" :value="color" @change="$emit('change', $event.target.value)">
	</div>
</template>

<script>
export default {
	props: {
		color: String,
	},
}
</script>

<style scoped>
.color-picker {
	height: 25px;
	width: 25px;
	padding: 0px;
}
</style>