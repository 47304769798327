<template>
	<b-modal ref="save-warning" id="save-warning" title="Warning">
		<p class="my-4">Please save your work regularly. Screenatron uses your browser to save data but sometimes, especially at Universities, this data can be wiped. Progress can be saved by going File -> Save from the top menu.</p>

		<template #modal-footer>
			<b-button variant="primary" @click="hide()">
				Ok
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import saveMixin from "../mixins/saveMixin"
import localforage from 'localforage'

export default {
	name: 'TheWarningModal',
	mixins: [
		saveMixin
	],
	mounted() {
		this.$root.$on('show-save-modal', () => {
			this.$refs['save-warning'].show();
		});
	},
	methods: {
		hide() {
			this.$refs['save-warning'].hide();
		},
		close() {
			// Reset store
			this.$store.dispatch('resetStore');
			// Clear localStorage
			localforage.clear();
			this.$refs['save-warning'].hide();
		}
	}
}
</script>

<style>
</style>