import { mapGetters } from 'vuex';
import * as reflib from "reflib";
import { Stream } from "../classes";
import filenameMixin from './filenameMixin';

export default {
	mixins: [filenameMixin],
	computed: {
		...mapGetters('references', ['getRefsArray']),
		...mapGetters('groups', ['getAllGroupRefIds', 'getSize']),
	},
	methods: {
		download(group, format) {
			// Get references from store
			const references = this.getRefsArray(this.getAllGroupRefIds);
			// Filter through references and select those in current group
			const groupRefs = references.filter(
				ref => ref.group === group.key
			);
			// Download
			this.downloadFile(
				groupRefs,
				this.getProjectName()
					+ "_"
					+ group.name
					+ " "
					+ this.getDateString()
					+ format.extention,
				format.driver
			);
		},
		downloadFile(content, filename, driver) {
			// Parse authors
			content.map(el => {
				if (Array.isArray(el.authors)) {
					// Authors already array
					return el;
				} else {
					// Authors is string
					if (typeof variable === 'string') {
						// Split by semicolon (for covidence exports)
						el.authors = el.authors.split(';');
						return el;
					} else {
						// Is neither array nor string, return empty array
						el.authors = [];
						console.log("WARN: author field was not formatted")
						return el.authors
					}
				}
			})
			// Generate xml file
			const stream = new Stream();
			reflib
				.output({
					format: driver,
					stream: stream,
					content: content
				})
				.on("finish", () => {
					const pom = document.createElement("a");
					const bb = new Blob([stream.getData()], { type: "text/plain" });

					pom.setAttribute("href", window.URL.createObjectURL(bb));
					pom.setAttribute("download", filename);

					pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
						":"
					);
					pom.draggable = true;
					pom.classList.add("dragout");

					pom.click();
				});
		}
	}
}